.openapi-doc-header {
  position: fixed;
  left: 0;
  top: 0;
  padding: 0 32px;
  width: 100%;
  height: 64px;
  background: #ffffff;
  box-shadow: 2px 2px 10px 0px rgba(97, 92, 153, 0.1);
  display: flex;
  align-items: center;
  justify-content: space-between;
  z-index: 10;

  &-left {
    img {
      width: 145px;
      height: 20px;
    }
  }

  &-right {
    color: #333333;
    font-size: 16px;
  }
}